<template>
    <!-- TARGET START -->
    <slot></slot>
    <!-- TARGET END -->
</template>

<script setup lang="ts">
    //force template inline
</script>

<script lang="ts">

export default {
    name: 'OTargetResolver',
    mounted() {
        console.log(this.$el?.nextElementSibling);
    },
};
</script>